.btnUpContainer {
    display: none;
    width: 10vw;
    height: 10vh;
    position: fixed;
    bottom: 20px;
    left: 10px;
  }
.btnUpContainer.visible{
    display: block;
}
.btnUp {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    background-color: #2ECC71; 
    box-shadow:0px 0px 7px -3px #000000 ;  
}

.btnUp i {
    color: black;
    font-size: large;
}

@media (max-width: 899px) and (orientation: landscape) {
    .btnUpContainer{
        bottom: 50px;
    }
       }
  
  
