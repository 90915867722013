.page_realisation {
    min-height: 100vh;
}

.btn_back{
    position: fixed;
    border:none;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 8%;
    margin-left: 5%;
    font-size: x-large;
    color: #2ECC71;
}

.ctn_rea{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.ctn_title_desc{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ctn_title_desc h3{
    margin-bottom: 0;
}
.ctn_title_desc p{
    margin-top: 0;
}
.ctn_techno_img_url{
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.ctn_techno_url{
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.div_techno h5, .div_url h5, .div_mission h5{
    text-align: center;
}
.div_url{
    text-align: center;
}
.techno{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }
  .div_techno .techno p {
    color: #2ECC71;
    width: 7vw;
    font-size: small;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 1.5px solid #2ECC71;
    border-radius: 20px;
  }
  .div_mission p {
    color: rgb(106, 102, 102);
    font-size: 15px;
  }
  .url{
    color: black;
    font-size: xx-large;
   
  }


@media only screen and (max-width: 1000px) {
    .ctn_rea{
        text-align: center;
    }
    .ctn_techno_img_url{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ctn_techno_url{
        width: 95vw;
    }
    .div_techno, .div_url, .div_mission{
        margin-top: 3vh;
    }
    .div_techno .techno p {
        width: 25vw;
    }
  
  }