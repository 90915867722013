.btn_dl {
    width: 20vw;
    height: 5vh;
    border-radius: 3vw;
    border: none;
    color: black;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    font-weight: 700;
   
}

.btn_dl:hover {
    background-color: #2ECC71;
    color: white;
}
@media only screen and (max-width: 800px) {
    .btn_dl{
        width: 30vw;
    }
     }
