.popup {
  width: 6vw;
  display: flex;
  align-items: center;
}

.popup-content {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.popup-content select {
  width: 5rem;
  height: 3vh;
  border-radius: 15px;
  border: none;
  background-color: #2ECC71;
  color: #333;
  outline: none;
  text-align: center;
  -webkit-appearance: none; /* Chrome et Safari */
  -moz-appearance: none;
  appearance: none;
}

@media only screen and (max-width: 899px) {
  .popup {
    width: 30vw;
  }
}
@media(max-width: 700px) and (orientation: landscape) {
 .popup-content select {
  height: 6vh;
 }
  }