.menu-bar {
  position: absolute;
  bottom: 0;
  height: 3px;
  background-color: #2ecc71;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.menu-bar.show {
  opacity: 1;
  visibility: visible;
  border-radius: 25px;
}

.nav {
  width: 60vw;
  display: flex;
  flex-direction: row;
  
  align-items: center;
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  backdrop-filter: blur(8px);
  background-color: #dddddccc;
}

ul {
  width: 45vw;
  display: flex;
  justify-content: space-around;
  padding: 0;
}

.nav li {
  font-size: 1.2rem;
  padding: 0.8rem;
  list-style: none;
  transition: background-color 0.5s ease, color 0.3s ease;
  color: #333;
}

.nav li:hover {
  color: #2ecc71;
}

.nav a {
  text-decoration: none;
}

.ctn-list-menu {
  display: flex;
  align-items: center;
}

.nav_right {
  width: 12rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
 
}

.ctn_icon_navigation a {
  color: black;
}


@media only screen and (max-width: 900px) {
  .nav{
    width: 70vw;
    height: 100%;
  }

}
@media (max-width: 899px) and (orientation: landscape) {
 .nav {
  height: 100vh;
 }
}
@media only screen and (max-width: 899px) {
  .nav{
    width: 95vw;
    height: 80%;
  }
  .nav li:hover {
    color: #12a54f;
  }
  .menu-bar {
    background-color: #12a54f;
  }
  
  .ctn-list-menu{
    width: 95vw;
  }
  ul{
    width: 100vw;
  }
  .nav li{
    font-size: medium;
    padding: 0
  }
  .nav_right{
    display: none;
  }
}