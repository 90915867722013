.ctn_about_social{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    padding :20px;
}
.footer_about{
    width: 45%;
}
.ctn_social{
    width: 25%;

}
.ctn_icon_social{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.ctn_icon_social a{
    color: rgb(255, 255, 255);
}
.ctn_copyright{
height: 15vh;
display: flex;
align-items: center;
}
.ctn_line_footer{
  display: flex;
  width: 100vw;
  
}
.line_footer{
  display: flex;
  width: 80vw;
  color: rgb(255, 255, 255);
  border: 0.5px solid;
  margin-left: auto;
  margin-right: auto;
}
.display_none{
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2vh;
  height: 10vh;
}

@media only screen and (max-width: 800px) {
  
  .footer_about{
    width: 70%;
  }
  .ctn_social{
    width: 30%;
  }
  }

  @media only screen and (min-width: 899px) {
    .display_none{
      display: none;
    }
   
    }
    @media (max-width: 899px) and (orientation: landscape) {
      
      .ctn_about_social{
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .ctn_social{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
      .display_none{
        height: 20vh;
      }
      
         }
    
    