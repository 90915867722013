#about{
  padding-top: 10vh;

}
.section_about{
  display: flex;
  flex-direction: column;
  padding-left: 2vw;
  padding-right: 2vw;
}
.line_about{
  margin-left: auto;
  margin-right: auto;
  background-color: #2ECC71;
  width: 10vw;
  height: 0.5vh;
  border-radius: 20px;
}
.ctn_presentation{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.ctn_presentation p {
  width: 100%;
  font-weight: 500;
  font-size: medium;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 5vh;
}
.ctn_techno{
  width: 50%;
  height: auto;
  
}
.ctn_line{
  display: flex;
  align-items: center;
  height: 10vh;
}
.line{
  display: flex;
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
  border: 0.5px solid;
}
.ctn_icon{
    width: 95vw;
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    margin-top: 3vh;
    
}
.ctn_icon div{
  width: 35vw;
}


@media only screen and (max-width:815px){
  #about{
    padding-top: 10vh;
  
  }
  .section_about{
    display: flex;
    flex-direction: column;
  }
  .ctn_presentation{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .ctn_line{
    display: flex;
    align-items: center;
    width: 100%;
    height: 0;
  }
  .line{
    display: flex;
    width: 95vw;
    height: 0;
    color: black;
    border: 0.5px solid;
   
  }
  .ctn_techno{
   width : 100%;
  }
  .ctn_icon div{
    width: 40vw;
  }
}

@media(max-width: 899px) and (orientation: landscape) {
  #about{
    padding-top: 18vh;
  }
  p{
    font-size: medium;
  }
}