#contact{
    min-height: 90vh;
    padding-top: 12vh;
}
.ctn-form {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    
}
.ctn-form label{
    margin-bottom: 1vh;
    font-weight: 500;
}

.ctn-form h2{
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
}
form{
    margin-left: auto;
    margin-right: auto;
    width: 50vw;

    display: flex;
    flex-direction: column;
    

}


input{
    margin-bottom: 2vh;
    border: none;
    
}
.inout-obj, .input-email{
    padding-left: 1vw;
    height: 5vh;
    color: black;
    outline: none;
    box-shadow:0px 0px 12px 0px rgba(86, 86, 86, 0.172);
    border-radius : 10px;

 
}
.input-msg{
    height: 20vh;
    border: none;
    resize: none;
    outline: none;
    border-radius : 10px;
    padding: 10px;
    padding-left: 1vw;
    box-shadow:0px 0px 12px 0px rgba(86, 86, 86, 0.172);
    font-family: 'Montserrat';

}
.div_btn_submit{
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}
.btn_submit{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 5vh;
    border: none;
    background-color: white;
    color: grey;
    box-shadow:0px 0px 12px 0px rgba(86, 86, 86, 0.172);
    border-radius : 10px;
    margin-top: 5vh;
    font-weight: 600;

}

.btn_submit:hover{
    background-color: #2ECC71;
    color: white;
    font-family: 'Montserrat';

}
  @media only screen and (max-width: 1000px){
    #contact{
        
        padding-top: 15vh;
    }
    form{
        width: 90vw;
    }
    .div_btn_submit{
        width: 60%;
    }
}

@media (max-width: 899px) and (orientation: landscape){
    #contact{
        padding-top: 25vh;
        margin-bottom: 5vh;
    }
    
    .ctn-form label{
        margin-bottom: 3vh;
    }
.ctn-form input{
    height: 5vh;
}

.btn_submit{
    width: 40vw;

    
}

  }