.img_carroussel {
    width: 60vw;
    height: 50vh;
    border-radius: 25px;
    box-shadow:0px 0px 12px 0px rgba(86, 86, 86, 0.172);
}
.img_carroussel img{
    object-fit: cover;
    width: 100%;
    height: 50vh;
    border-radius: 25px;
    
}
.ctn-btn{
    display: flex;
    justify-content: space-between;
    margin-top: -28vh;
    height: 10vh;
    width: 100%;
}
.btn-next, .btn-previous{
    width: 5vw;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: rgba(255, 255, 255, 0);
    color: rgba(53, 52, 52, 0.703);
    border: none;
    
}


@media only screen and (max-width: 1000px) {
    .photoLogement img{
        height: 30vh;
    }
    .ctn-btn{
        width: 80vw;
    }
    
   
    .img_carroussel{
        width: 95vw;
        height: 45vh;
    }
    .img_carroussel img {
        height: 45vh;
    }
    .ctn-btn{
        width: 100%;
    
    }
   
}

@media only screen and (max-width : 800px){
    .img_carroussel{
        width: 95vw;
        height: 35vh;
    }
    .img_carroussel img {
        height: 35vh;
    }
    .ctn-btn{
        width: 100%;
        margin-top: -23vh;
    }
}
@media only screen and (max-width : 550px){
    .img_carroussel{
        width: 95vw;
        height: 30vh;
    }
    .img_carroussel img {
        height: 30vh;
    }
    .ctn-btn{
        width: 100%;
        margin-top: -20vh;
    }
    
}