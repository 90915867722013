p, h1, h2, h3, h4, h5 {
  font-family: 'Montserrat';
}

#root, html{
  width: 100vw;
  margin: 0;
  padding: 0;
}

header{
  width: 100vw;
  height: 10vh;
  z-index: 3;
  display: flex;
  text-align: center;
  position: fixed;
  align-items: center;
  margin-top: 1vh;
 
}

body { 
  background-color: rgba(255, 255, 255, 0.415);
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
footer{
  height: 35vh;
  background-color: rgb(41, 41, 41);
  color:#eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  
}
nav {
  background-color: #f4f3f0cc;
}
:root {
  --font-color:#333;
  --background-color: white;
  --back-color: #f4f3f0cc;
  --link-color: cornflowerblue;
  
}

/* 2 */

[data-theme="dark"] {
  --font-color: #eee;
  --background-color: #333;
  --back_color:rgb(41, 41, 41);
  --link-color: lightblue;
}

/* 3 */

body {
  background-color: var(--background-color);
  color: var(--font-color);
}

 i, h2, li, a, .line, .label { 
  color: var(--font-color);
}

.mouse{
  border-color: var(--font-color);
}


@media only screen and (max-width: 900px) {
  footer{
   min-height: 50vh;
  }
 }

@media(max-width: 899px) and (orientation: landscape) {
  header{
    height: 15vh;
  }

}