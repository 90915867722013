#realisation {
  padding-top: 11vh;
  padding-bottom: 5vh;
}
.ctn-data {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 5vh;
}
.lien_data{
  width: -10vw;
}
.data {
  margin: 10px;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  width: 45vw;
  height: 35vh;
  box-shadow:0px 0px 12px 0px rgba(86, 86, 86, 0.172);
}

.data img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.title_rea {
  width: 100%;
  margin-top: -20vh;
  text-align: center;
  color: rgb(255, 255, 255);
  position: absolute;
  display: flex;
  justify-content: center;
  
}

.line_rea{
  margin-left: auto;
  margin-right: auto;
  background-color: #2ECC71;
  width: 10vw;
  height: 0.5vh;
  border-radius: 20px;
}

.data:hover {
  cursor: pointer;
}

.data:hover img {
  filter: brightness(0.5);
}

.data:hover .description {
  display: block;
}

.data a {
  width: 100vw;
}

.data p {
  width: 100%;
  margin: 0;
  color: white;
  text-align: center;
  margin-bottom: 0vh;
  margin-top: 0;
}

.data h5 {
  color: rgb(255, 255, 255);
}

.data img {
  width: 100%;
  height: 35vh;
  object-fit: cover;
  border: 0.5px solid rgba(128, 128, 128, 0.323);
}

@media only screen and (max-width: 1000px){
  #realisation {
    padding-top: 13vh;
    padding-bottom: 5vh;
  }
   .ctn-data{
    width: 100vw;
  
   }
   .data{
    width: 100vw;
   }
   .data img{
    width:100%;
   }
  .modal-content{
    width: 80vw;
    height: 75vh;
    padding: 5px;
    padding-top: 5vh;
  }
  .close{
    top: 5vh;
  }
  .div_row{
    width: 100%;
  }
  .div_next{
    height: 35vh;
    flex-flow: column ;
    margin-top: 3vh;
    
  }
  .div_mission, .div_url{
    width: 100%;
  }
  .div_url{
    margin-top: 5vh;
  }
  .div_techno{
    width: 100%;
  }
  .div_techno .techno p {
    width: 20vw;
  }
  .div_collapse{
   margin-left: 10vw;
  }
  
  h5{
    margin-top: 0;
    margin-bottom: 0vh;
  }
  .div_next p{
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
}
@media(max-width: 899px) and (orientation: landscape) {
 #realisation {
  padding-top: 20vh;
 }
     }

