.div_copyButton{
    width: 25vw;
}
.div_copyButton button i:hover{
    color: #2ECC71;
}

@media only screen and (max-width: 600px){
    .div_copyButton {
        width: 80vw;
    }
}
@media(max-width: 899px) and (orientation: landscape) {
  .div_copyButton{
    width: 50vw;
  }
  }