
.ctn_about{
    height: 90vh;
    display: flex;
    align-items: center;
    text-align: center ;
}

.ctn_text{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'lato';
}
.ctn_text_name h2{
    margin-bottom: 0;
}
.text_background h4{
    margin-top: 0;
}
.btn_contact{
    width: 15vw;
    height: 5vh;
    border: none;
    border-radius: 5px;
    margin-top: 5vh;

}

.middle {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mouse {
    width: 20px;
    height: 40px;
    border: 2px solid #333333;
    border-radius: 60px;
}

.mouse::before {
    content: '';
    width: 7px;
    height: 7px;
    position: absolute;
    top: 30px;
    background-color: #2ECC71;   
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    opacity: 1;
    animation: mouse 1.5s infinite;
    -webkit-animation: mouse 1.5s infinite;

}

@keyframes mouse {
    from {
        opacity: 1;
        top: 1px;
    }
    to {
        opacity: 0;
        top: 39px;
    }
}
@-webkit-keyframes mouse {
    from {
        opacity: 1;
        top: 1px;
    }
    to {
        opacity: 0;
        top: 1px;
    }
}


@media only screen and (max-width: 800px) {
    .ctn_text{
        width: 90%;
    }

  }